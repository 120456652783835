body {
    color: $text-color;
    background: $bg-color;
}

ul {
    padding-left: 40px;
}

p {
    word-spacing: .05em;
}

[hidden] {
    display: none !important;
}

article>*+*:not(li),
.e-content>*+*:not(li) {
    margin-top: 1em;
}
.landing {

    main {
        display: grid;
        gap: 1.25rem;
        grid-template-columns: repeat(6, 1fr);
    }

    h1 {
        grid-column: span 6;
    }
}
/* Fonts */
@import url("https://fonts.googleapis.com/css?family=Lora:400,400i,700,700i");
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,300i");


/* COLORS */

// Brand colors
$storm-cloud: #4e4e4e;
$silver: #eef6ff;
$ruby: #90253c;
$wet-sand: lighten($storm-cloud, 40%);

// Applied color styles
$text-color: $silver;
$bg-color: $storm-cloud;

$link-color: $wet-sand;
$link-hocus-color: $silver;

$tag-color: $wet-sand;
$tag-bgcolor: $ruby;
$tag-color-inverted: $ruby;
$tag-bgcolor-inverted: $wet-sand;

$border-color: $wet-sand;
$bq-border-color: $silver;
$bq-background-color: $wet-sand;

$card-border-color: $wet-sand;

$code-color: $storm-cloud;
$code-bgcolor: $wet-sand;

/* TYPOGRAPHY */

$serif: Georgia,
serif;
$sans: 'Montserrat',
Verdana,
Helvetica,
Arial,
sans-serif;

/* BREAKPOINTS */

// 401px
$break-small: "screen and (min-width: 25.0625rem)";

// 450px
$break-medium: "screen and (min-width: 28.125rem)";

// 600px
$break-large: "screen and (min-width: 37.5rem)";

// 960px
$break-xlarge: "screen and (min-width: 60rem)";
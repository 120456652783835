.meta {

    &,
    & date {
        font-family: $sans;
        font-size: .875em;
    }

    ul {
        @include horizontal-list;
        margin-top: 1rem;
    }

    li {
        margin-right: .625rem;
    }
}
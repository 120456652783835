/* Article Page */

.article {

    article {
        display: grid;
        gap: 1.25rem;
        grid-template-columns: repeat(4, 1fr) repeat(2, 1fr);
        grid-template-rows: max-content 1fr max-content;
    }

    h1,
    .content,
    article>nav {
        grid-column: span 4;
    }

    .meta {
        grid-column: 5 / span 2;
        grid-row: 2;
    }
}

// Other Styles
.visible-medium {
    display: none;

    @media #{$break-medium} {
        display: inline;
    }
}
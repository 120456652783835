/* Base Layout */

// Page Grid
.o-base-layout {
    display: grid;
    gap: 1.25rem;

    @include bp('medium') {
        grid-template-columns: 1.25rem minmax(min-content, 150px) repeat(6, minman(300px, 1fr)) 1.25rem;
        grid-template-areas:
            ". nav main main main main main main ."
            ". nav footer footer footer footer footer footer .";
    }
}

nav {
    grid-area: nav;
}

main {
    grid-area: main;
}

footer {
    grid-area: footer;
}
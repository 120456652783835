/* As a means for reducing the amount of styles that get overridden,
    margins and paddings for elements are set in the elements file,
    rather than set to -0- in a reset.
*/

/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Set core body defaults */
body {
    margin: 0;
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5; //base - consider moving to typogrqaphic scale declarations
}

/* Anchor elements without a class get default styles */
a:not([class]) {
    text-decoration-skip-ink: auto;
}

/* Removes the weird gap that exists on images */
img {
    vertical-align: middle;
}

/* Responsive images, unless a width has been specified */
img:not([width]) {
    max-width: 100%;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
}

/* Remove indent */
blockquote {
    margin-left: 0;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}
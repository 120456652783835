.c-card {
    grid-column: span 2;
    gap: 1rem;
    display: grid;
    grid-template-rows: max-content 1fr max-content;
    border: solid 1px $card-border-color;
    padding: 10px;

    h2 {
        margin: 0;
    }

    p {
        margin: 0;
    }
}
/* Site Navigation */
.c-site-nav {
    display: grid;
    grid-template-rows: max-content 1fr;
    font-family: $sans;
    text-transform: uppercase;

    em {
        font-style: normal;
    }

    ul {
        padding-left: 0;
    }

    li {
        list-style: none;
    }

    [data-current="current"] {}
}
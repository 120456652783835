html {
    font-family: $serif;
    font-weight: 400;
    font-display: optional;
}

body {
    font-size: 1rem;

    // can this be rewritten w/o media query?
    @media #{$break-xlarge} {
        font-size: 1.125rem;
    }
}

// Links
a {

    &,
    &:visited {
        color: $link-color;
    }

    &:hover,
    &:active,
    &:focus {
        color: $link-hocus-color;
    }
}

sup {
    font-size: .75rem;
}

// The hash displayed when hovering over a heading. Allows the user to hop to a specific portion of a page.
.direct-link {
    padding: 0 1.25rem;

    &,
    &:visited {
        color: transparent;
    }

    :hover>&,
    :focus>& {
        color: $link-color;
    }

    &:hover,
    &:active,
    &:focus {
        color: $link-hocus-color;
    }
}

/* Post Tags */
[rel="tag"] {
    display: inline-block;
    font-family: $serif;
    font-size: .625rem;
    letter-spacing: .1em;
    text-decoration: none;
    text-transform: uppercase;
    margin-bottom: .5em;
    padding: 0 .5em;
    line-height: 2em;
    background-color: $tag-bgcolor;
    border: 1px solid rgba($border-color, 0.25);
    border-radius: .25em;

    &+& {
        margin-left: .5em;
    }

    &,
    &:visited {
        color: $tag-color;
    }

    &:hover {
        border: 1px solid $border-color;
        background-color: $tag-bgcolor-inverted;
        color: $tag-color-inverted;
    }
}

// Headings
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $sans;
    letter-spacing: -0.03125em;
}

h2,
h2:first-child,
h3,
h4,
h5,
h6 {
    font-weight: normal;
    margin-top: 1.5rem;
}

h1 {
    font-variant: small-caps;
    font-size: 2rem;
    font-weight: bold;
}

h2 {
    font-size: 1.5rem;
    display: flex;
}

p {
    font-weight: 300;
    letter-spacing: .0625rem;
}

blockquote {
    border-left: ridge 15px rgba($bq-border-color, .5);
    border-top: ridge 7.5px rgba($bq-border-color, .5);
    background-color: rgba($bq-background-color, .3);
    padding: 1em;
}

.content li:not(:first-child) {
    margin-top: .5em;
}
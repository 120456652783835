@import "generic/_reset";
@import "bedrock/_variables";
@import "bedrock/_mixins";
@import "bedrock/_global";
@import "bedrock/_type";
@import "objects/_base";
@import "objects/_landing";
@import "objects/_article";
@import "components/_card";
@import "components/_nav";
@import "components/_figure";
@import "components/_footer";
@import "components/_form";
@import "components/_meta";
@import "utilities/_visually-hidden";
@import "syntax/_highlight";
@import "syntax/_custom";

/* REMINDER:  Use more n + n selectors. reduce bloat
created by undoing something with :first/last-child */

/*
================
TODO
================

Handle direct-link adding large space after
Ex: heading http://localhost:8080/posts/new-approach-new-site/
*/
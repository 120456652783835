@mixin grid-base {
  display: grid;

  /* Fix bug with grid, where content breaks out of container */
  min-width: 0;
  min-height: 0;

  >* {
    min-width: 0;
    overflow: hidden;
  }

  /* End grid bug fix */
}

@mixin horizontal-list {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;

  li {
    list-style-type: none;
    margin-left: -0.25rem;

    &:before {
      /* https://unfetteredthoughts.net/2017/09/26/voiceover-and-list-style-type-none/ */
      /* add zero-width space trigging VoiceOver to announce the list items #accessibility */
      content: "\200B";
    }
  }
}

@mixin visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
}

$points: ( // 401px
  'small': 25.0625rem,

  // 450px
  'medium': 28.125rem,

  // 600px
  'large': 37.5rem,

  // 960px
  'xlarge': 60rem) !default;

@mixin bp($point) {

  // If the key exists in the map
  @if map-has-key($points, $point) {

    // Prints a media query based on the value
    @media only screen and (min-width: #{map-get($points, $point)}) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$point}`. "
    +"Available breakpoints are: #{map-keys($points)}.";
  }
}